var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.label !== ""
        ? _c(
            "div",
            {
              staticClass: "label-text",
              attrs: { id: `label_${_vm.id}`, for: _vm.id }
            },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          )
        : _vm._e(),
      _c("input", {
        attrs: { name: _vm.id, type: "hidden" },
        domProps: { value: _vm.value }
      }),
      _c("ejs-colorpicker", {
        attrs: {
          id: _vm.id,
          inline: true,
          modeSwitcher: false,
          change: _vm.onChange,
          enableOpacity: false,
          cssClass: "e-hide-value",
          value: _vm.value
        },
        on: { modelchanged: _vm.emitValue }
      }),
      _c("ejs-dropdownbutton", {
        ref: _vm.dropDownId,
        attrs: {
          id: _vm.dropDownId,
          cssClass: "colorpicker-dropdown",
          target: ".e-colorpicker-wrapper",
          iconCss: "e-dropdownbtn-preview",
          beforeClose: _vm.beforeDropDownClose,
          open: _vm.dropDownOpen
        }
      }),
      _c("div", { attrs: { id: _vm.dataMsgContainerId } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
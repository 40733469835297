var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isLoggedIn
    ? _c(
        "v-app",
        [
          _c("app-navigation", { attrs: { menu: _vm.menu } }),
          _c("app-toolbar"),
          _c(
            "v-main",
            {
              style: [
                _vm.$vuetify.breakpoint.mobile
                  ? {}
                  : { "padding-left": "260px" }
              ]
            },
            [_c("v-container", [_vm._t("default")], 2)],
            1
          ),
          _c("change-password-modal"),
          _c("app-footer")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.errors.length > 0
    ? _c(
        "div",
        { attrs: { id: "generic-error" } },
        _vm._l(_vm.errors, function(error) {
          return _c("div", { key: error }, [
            _c("label", { staticClass: "e-error" }, [_vm._v(_vm._s(error))])
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ejs-datetimepicker", {
        ref: _vm.id,
        class: _vm.cssClass,
        attrs: {
          id: _vm.id,
          name: _vm.id,
          floatLabelType: "Always",
          placeholder: _vm.placeholder,
          value: _vm.value,
          "data-msg-containerid": _vm.dataMsgContainerId,
          focus: _vm.onFocus,
          allowEdit: _vm.allowEdit
        },
        on: { modelchanged: _vm.emitValue }
      }),
      _c("div", { attrs: { id: _vm.dataMsgContainerId } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import appointmentApi from "@/api/appointment";
const state = {
    appointments: [],
    searchResult: []
};
const actions = {
    getAll(context) {
        return context.getters.getAppointmets;
    },
    add(context, payload) {
        payload.appointments.forEach(appointment => context.commit("addAppointment", appointment));
    },
    update(context, payload) {
        payload.appointments.forEach(appointment => context.commit("deleteAppointment", appointment));
        payload.appointments.forEach(appointment => context.commit("addAppointment", appointment));
    },
    delete(context, payload) {
        payload.appointments.forEach(appointment => context.commit("deleteAppointment", appointment));
    },
    async search(context, payload) {
        const searchResult = await appointmentApi.search(payload.query, payload.startDate, payload.endDate, payload.offices, payload.customers, payload.services, payload.employees);
        context.commit("setSearchResult", searchResult);
        return searchResult;
    },
    outsideWorkingHours(context, payload) {
        return appointmentApi.outsideWorkingHours(payload.startDateTime, payload.endDateTime, payload.employeeId);
    },
    employeeOutsideWorkingHours(context, payload) {
        return appointmentApi.employeeOutsideWorkingHours(payload.employeeId, payload.officeId, payload.dayOfWeek, payload.startTime, payload.endTime, payload.startDate, payload.endDate);
    },
    searchFirstAvailability(context, payload) {
        return appointmentApi.searchFirstAvailability(payload.data);
    },
    searchFirstAvailabilityByQuery(context, payload) {
        return appointmentApi.searchFirstAvailabilityByQuery(payload.data);
    }
};
const getters = {
    getAppointmets(state) {
        return state.appointments;
    },
    getSearchResult(state) {
        return state.searchResult;
    }
};
const mutations = {
    addAppointment(state, appointment) {
        state.appointments.push(appointment);
    },
    deleteAppointment(state, appointment) {
        state.appointments = state.appointments.filter((value) => value.Id != appointment.Id);
    },
    setSearchResult(state, searchResult) {
        state.searchResult = searchResult;
    }
};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(this.title))]),
      _c(
        "app-form",
        {
          attrs: {
            id: "change-password-form",
            submitAction: _vm.changePassword,
            submitSuccessAction: _vm.changePasswordSuccess,
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "currentPassword",
                  placeholder: _vm.$t("account.changePassword.currentPassword"),
                  type: "password"
                },
                model: {
                  value: _vm.currentPassword,
                  callback: function($$v) {
                    _vm.currentPassword = $$v
                  },
                  expression: "currentPassword"
                }
              }),
              _c("app-textbox", {
                attrs: {
                  id: "newPassword",
                  placeholder: _vm.$t("account.changePassword.newPassword"),
                  type: "password"
                },
                model: {
                  value: _vm.newPassword,
                  callback: function($$v) {
                    _vm.newPassword = $$v
                  },
                  expression: "newPassword"
                }
              }),
              _c("app-textbox", {
                attrs: {
                  id: "newPasswordConfirm",
                  placeholder: _vm.$t(
                    "account.changePassword.newPasswordConfirm"
                  ),
                  type: "password"
                },
                model: {
                  value: _vm.newPasswordConfirm,
                  callback: function($$v) {
                    _vm.newPasswordConfirm = $$v
                  },
                  expression: "newPasswordConfirm"
                }
              })
            ],
            1
          ),
          _c("app-form-buttons", {
            attrs: {
              addSubmitButton: true,
              submitButtonText: "account.changePassword.submit",
              addCancelButton: true,
              cancelAction: _vm.cancel
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
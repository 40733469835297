var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-buttons-wrapper" },
    [
      _vm.addSubmitButton
        ? _c(
            "app-button",
            {
              attrs: { cssClass: "e-primary" },
              nativeOn: {
                click: function($event) {
                  return _vm.submitAction.apply(null, arguments)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t(_vm.submitButtonText)) + " ")]
          )
        : _vm._e(),
      _vm.addCancelButton
        ? _c(
            "app-button",
            {
              attrs: { type: "button", cssClass: "e-secondary" },
              nativeOn: {
                click: function($event) {
                  return _vm.cancelAction.apply(null, arguments)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t(_vm.cancelButtonText)) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isVisible
    ? _c(
        "v-list-item",
        { attrs: { to: _vm.menuItem.path, value: "true" } },
        [
          _c(
            "v-list-item-icon",
            [
              _c("v-icon", {
                attrs: { small: "" },
                domProps: { textContent: _vm._s(_vm.menuItem.icon) }
              })
            ],
            1
          ),
          _c("v-list-item-title", {
            domProps: { textContent: _vm._s(_vm.$t(_vm.menuItem.title)) }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ejs-toast", {
    ref: "toast",
    attrs: {
      id: "toast",
      position: _vm.position,
      showCloseButton: true,
      click: _vm.onClick
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ejs-switch", {
    class: _vm.cssClass,
    attrs: { id: _vm.id, disabled: !_vm.enabled, value: _vm.value },
    on: { modelchanged: _vm.emitValue }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
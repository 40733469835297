var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ejs-chiplist",
    { attrs: { cssClass: _vm.cssClass } },
    [
      _c(
        "e-chips",
        _vm._l(_vm.values, function(value, index) {
          return _c("e-chip", {
            key: index,
            attrs: {
              text: value.text,
              cssClass: value.cssClass,
              leadingIconCss: value.leadingIcon,
              htmlAttributes: _vm.getDataAttributes(value)
            }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isVisible
    ? _c(
        "v-list-group",
        {
          attrs: { value: _vm.isActive },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function() {
                  return [
                    _c("v-list-item-title", {
                      domProps: {
                        textContent: _vm._s(_vm.$t(_vm.menuItem.title))
                      }
                    })
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            3119403265
          )
        },
        [
          _c("v-icon", {
            attrs: { slot: "prependIcon", small: "" },
            domProps: { textContent: _vm._s(_vm.menuItem.icon) },
            slot: "prependIcon"
          }),
          _vm._l(_vm.menuItem.children, function(item) {
            return _c("app-navigation-item", {
              key: item.key,
              attrs: { menuItem: item }
            })
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
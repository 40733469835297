var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ejs-numerictextbox", {
        class: _vm.cssClass,
        attrs: {
          id: _vm.id,
          name: _vm.id,
          floatLabelType: "Always",
          placeholder: _vm.placeholder,
          format: _vm.format,
          type: _vm.type,
          enabled: _vm.enabled,
          value: _vm.value,
          "data-msg-containerid": _vm.dataMsgContainerId
        },
        on: { modelchanged: _vm.emitValue }
      }),
      _c("div", { attrs: { id: _vm.dataMsgContainerId } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ejs-checkbox", {
        attrs: {
          id: _vm.id,
          label: _vm.label,
          checked: _vm.checked,
          "data-msg-containerid": _vm.dataMsgContainerId
        },
        on: { modelchanged: _vm.emitValue }
      }),
      _c("div", { attrs: { id: _vm.dataMsgContainerId } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-footer",
    { attrs: { app: "", dark: "" } },
    [
      _c("div", [
        _c(
          "a",
          { attrs: { href: "https://www.kinesisport.com/", target: "_blank" } },
          [
            _c("v-img", {
              staticStyle: { height: "28px", width: "52px" },
              attrs: { src: require("@/assets/kinesis-sport-logo.png") }
            })
          ],
          1
        )
      ]),
      _c("div", { staticStyle: { "margin-left": "12px" } }, [
        _vm._v("Calendario © 2021-" + _vm._s(_vm.currentYear))
      ]),
      _c("v-spacer"),
      _c("div", [_vm._v("ver. " + _vm._s(_vm.version))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
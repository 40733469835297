var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        app: "",
        clipped: "",
        fixed: "",
        permanent: !_vm.$vuetify.breakpoint.mobile,
        width: "260px"
      },
      model: {
        value: _vm.isMenuVisible,
        callback: function($$v) {
          _vm.isMenuVisible = $$v
        },
        expression: "isMenuVisible"
      }
    },
    [
      _c(
        "v-list",
        { attrs: { dense: "" } },
        [
          _c(
            "v-list-item-group",
            { attrs: { color: "primary" } },
            [
              _vm._l(_vm.menu, function(item) {
                return [
                  item.children
                    ? _c("app-navigation-group-item", {
                        key: item.key,
                        attrs: { menuItem: item }
                      })
                    : _c("app-navigation-item", {
                        key: item.key,
                        attrs: { menuItem: item }
                      })
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app-bar",
    {
      style: [_vm.$vuetify.breakpoint.mobile ? { "padding-left": "16px" } : {}],
      attrs: { app: "", "clipped-left": "", dark: "", height: "46px" }
    },
    [
      _c("v-app-bar-nav-icon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$vuetify.breakpoint.mobile,
            expression: "$vuetify.breakpoint.mobile"
          }
        ],
        on: { click: _vm.showMenu }
      }),
      _c(
        "v-toolbar-title",
        {
          staticClass: "white--text",
          style: [
            _vm.$vuetify.breakpoint.mobile ? {} : { "padding-left": "0" }
          ],
          attrs: { height: "inherit" }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.$vuetify.breakpoint.mobile,
                  expression: "!$vuetify.breakpoint.mobile"
                }
              ],
              staticClass: "toolbar-logo-container",
              staticStyle: { width: "234px", height: "36px" }
            },
            [
              _c("v-img", {
                staticClass: "toolbar-logo",
                staticStyle: {
                  width: "216px",
                  height: "36px",
                  margin: "5px 10px"
                },
                attrs: { src: require("@/assets/logo-admin.png") }
              })
            ],
            1
          )
        ]
      ),
      _c("v-spacer"),
      _c(
        "v-menu",
        {
          attrs: { "content-class": "profile-menu", "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g({ attrs: { id: "profile-btn", depressed: "" } }, on),
                    [
                      _c("v-avatar", { attrs: { size: "36px" } }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/dummy.jpg"),
                            alt: "Avatar"
                          }
                        })
                      ]),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.$vuetify.breakpoint.mobile,
                              expression: "!$vuetify.breakpoint.mobile"
                            }
                          ],
                          staticClass: "white--text profile-name",
                          attrs: { bgz: "" }
                        },
                        [_vm._v(" " + _vm._s(_vm.name) + " ")]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "v-list-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$vuetify.breakpoint.mobile,
                  expression: "$vuetify.breakpoint.mobile"
                }
              ]
            },
            [
              _c(
                "v-list-item-avatar",
                [
                  _c("v-img", {
                    attrs: { src: require("@/assets/dummy.jpg"), alt: "Avatar" }
                  })
                ],
                1
              ),
              _c("v-list-item-title", [_vm._v(_vm._s(_vm.name))])
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list-item",
            {
              staticClass: "change-password-button",
              on: { click: _vm.changePassword }
            },
            [
              _c("v-list-item-title", [
                _vm._v(_vm._s(_vm.$t("account.profile.changePassword")))
              ])
            ],
            1
          ),
          _c(
            "v-list-item",
            { staticClass: "signout-button", on: { click: _vm.logout } },
            [
              _c("v-list-item-title", [
                _vm._v(_vm._s(_vm.$t("account.profile.signOut")))
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
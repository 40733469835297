var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ejs-dialog", {
    ref: "changePasswordDialog",
    attrs: {
      id: "changePasswordDialog",
      content: _vm.dialogContentTemplate,
      isModal: "true",
      width: "460px",
      visible: false
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ejs-button",
    {
      attrs: {
        cssClass: _vm.cssClass,
        iconCss: _vm.iconCss,
        disabled: _vm.disabled
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }